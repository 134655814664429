import {VueSlideToggle} from 'vue-slide-toggle';
import Accordion from "./accordion/index.vue"

export default {
  name: "current-orders",
  components: {
    VueSlideToggle,
    Accordion,
  },
  data() {
    return {
      order:[
        {
          'id':124536666666,
          'date': '20.10.2020',
          'state': 'Відправлено',
          'sum': '2016 грн',
          'ord':[
            {
              img:'',
              title:'Сыворотка ГИАЛУРОНОВАЯ КИСЛОТА 0.5% И РАСТИТЕЛЬНЫЕ ЭКСТРАКТЫ',
              code:'18891451112',
              price:'490 грн',
              count:'10',
              amount:'4900 грн',
              discount: true
            },
            {
              img:'',
              title:'Сыворотка ВИТАМИН С 5%, 30 мл',
              code:'585585585585',
              price:'731 грн',
              count:'10',
              amount:'7310 грн',
              discount: false
            },
            {
              img:'',
              title:'Сыворотка АЗЕЛАИНОВАЯ КИСЛОТА 10% И ВИТАМИН С, 30мл',
              code:'123123123213',
              price:'1043 грн',
              count:'2',
              amount:'2086 грн',
              discount: true
            },
          ]
        },
        {
          'id':22222222221,
          'date': '23.10.2020',
          'state': 'В обробці',
          'sum': '4016 грн',
          'ord':[
            {
              img:'',
              title:'Сыворотка ГИАЛУРОНОВАЯ КИСЛОТА 0.5% И РАСТИТЕЛЬНЫЕ ЭКСТРАКТЫ',
              code:'18891451112',
              price:'490 грн',
              count:'10',
              amount:'4900 грн',
              discount: true
            },
            {
              img:'',
              title:'Сыворотка ВИТАМИН С 5%, 30 мл',
              code:'585585585585',
              price:'731 грн',
              count:'10',
              amount:'7310 грн',
              discount: false
            },
            {
              img:'',
              title:'Сыворотка АЗЕЛАИНОВАЯ КИСЛОТА 10% И ВИТАМИН С, 30мл',
              code:'123123123213',
              price:'1043 грн',
              count:'2',
              amount:'2086 грн',
              discount: true
            },
          ]
        },

      ],
    };
  },
  methods: {}

};
