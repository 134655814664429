import {VueSlideToggle} from 'vue-slide-toggle';

export default {
  name: "current-orders",
  components: {
    VueSlideToggle
  },
  props:['order'],
  data() {
    return {
      openItem:false,

    };
  },
  methods: {}

};
